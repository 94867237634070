<template>
    
    <div class="login standalone">
        <div>
            <img src="@/assets/images/brand/star-logo-full.png" class="full-logo" />
            <div class="danger" v-if="errorMessage">
                {{errorMessage}}
            </div>
            <div class="form-group">
                <label>
                    Username
                    <input type="text" v-model="username" />
                </label>
            </div>
            <div class="form-group">
                <label>
                    Password
                    <input type="password" v-model="password" />
                </label>
            </div>
            <div class="controls" v-if="!loggingIn">
                <button :disabled="!username.length || !password.length" @click="login">Log in</button>
            </div>
            <div class="controls">
                <router-link :to="{ name: 'UserResetPassword' }">Forgot password?</router-link>
            </div>
        </div>
    </div>
    
</template>

<script>
    import axiosInstance from '../../connections/AxiosInstance.js';

    export default {

        components: {
        },
        data: function(){
            return {
                username: 'starly@test.com',
                password: 'password',
                loggingIn: false,
                errorMessage: ''
            }
        },
        methods: {
            login: function(){
                this.loggingIn = true;
                this.errorMessage = "";
                const $this = this;
                axiosInstance({
                    method: 'post', 
                    url: 'User/login',    
                    headers: { 'Content-Type': 'text/json' },
                    data: {
                        "email": $this.username,
                        "password": $this.password
                    },
                }).then(function(response){
                    console.log(response.data);
                    if (response.status !== 200){
                        $this.errorMessage = "Odd response"
                        console.log(response);
                    } else {
                        $this.$store.commit('userLogin', response.data);
                        $this.loggingIn = false;
                        $this.$router.push("/reporting");
                    }
                }, function(error){
                    try{
                        $this.errorMessage = error.response.data.message;
                    }
                    catch(e){
                        $this.errorMessage = "Unknown error, please try again or contact technical support if the problem persists."
                    }
                    $this.loggingIn = false;
                });
            }
        },
        computed: {
            jwt: function(){
                return this.$store.state.jwt;
            }
        }
    }

</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.login{


    .form-group{
        max-width: 280px;
        margin: 0 auto;
    }

    .controls{
        text-align: center;
        padding: $space-standard;
    }
    .full-logo{
        width: 200px;
        margin: 0px auto 0;
        display: block;
    }
    button{
        padding: 0 50px;
    }

    .danger{
        text-align: center;
        padding: $space-standard;
    }
}
</style>